<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con">
                <div class="con_table">
                    <div class="con_top mb-10">
                        <h2><span>■</span> 기본정보</h2>
                    </div>

                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>고객명 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <input v-model="name" type="text" class="w-200px" value=""> <br>
                                </td>
                                <th>성별 <span class="red">*</span></th>
                                <td class="left pl-20">
                                    <select v-model="gender" class="w-60px">
                                        <option value="">성별</option>
                                        <option value="남자">남</option>
                                        <option value="여자">여</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>생년월일</th>
                                <td class="left pl-20">
                                    <crm-datepicker v-model="birth" :parentDate="birth" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                                </td>
                                <th>연령선택</th>
                                <td class="left pl-20">
                                    <select class="w-120px" v-model="ageRange">
                                        <option value="">연령선택</option>
                                        <option value="10대 이하">10대 이하</option>
                                        <option value="20대">20대</option>
                                        <option value="30대">30대</option>
                                        <option value="40대">40대</option>
                                        <option value="50대이상">50대이상</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>이메일</th>
                                <td colspan="3" class="left pl-20">
                                    <input v-model="email" type="text" class="w-200px">
                                </td>
                            </tr>
                            <tr>
                                <th>휴대폰 번호 <span class="red">*</span></th>
                                <td class="left">
                                    <select class="ml-10" v-model="phone1" id="phone1" name="phone1">
                                        <option value="010">010</option>
                                        <option value="011">011</option>
                                        <option value="016">016</option>
                                        <option value="017">017</option>
                                        <option value="018">018</option>
                                        <option value="019">019</option>
                                    </select> -
                                    <input type="text" v-model="phone2" style="width:100px;" id="phone2" name="phone2" value=""> -
                                    <input type="text" v-model="phone3" style="width:100px;" id="phone3" name="phone3" value="">
                                    <button type="button" class="default-btn ml-20" @click="checkPhoneVaild">중복확인</button>
                                </td>
                                <th>유입경로</th>
                                <td class="left pl-20">
                                    <select v-model="idxCrmMetaRoute" class="w-120px">
                                        <option value="-1">유입경로DB</option>
                                        <option v-for="(item, index) of routeList" :key="index" :value="item.idx">{{item.type}}</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <th>파일첨부</th>
                                <td colspan="3" class="left pl-20">
                                    <div class="mt-20 mb-20">
                                        <span class="ml-10" v-if="attachFileUrl" style="cursor:pointer">
                                            <a @click="newOpen(attachFileUrl)" class="w-300px pointer txt-black">{{attachFileName}}</a>
                                        </span>
                                        <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile.click()">파일 선택</button>
                                        <span v-if="attachFile" class="ml-10 bold" style="cursor:pointer" >{{attachFile.name}}</span>
                                        <span class="error ml-10">※ 20mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span>
                                        <input v-show="false" ref="attachFile" type="file" @change="uploadFile($event, $refs.attachFile, 1)" />
                                    </div>
                                    <div class="mb-20">
                                        <span class="ml-10" v-if="attachFileUrl2" style="cursor:pointer">
                                            <a @click="newOpen(attachFileUrl2)" class="w-300px pointer txt-black">{{attachFileName2}}</a>
                                        </span>
                                        <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile2.click()">파일 선택</button>
                                        <span v-if="attachFile2" class="ml-10 bold" style="cursor:pointer" >{{attachFile2.name}}</span>
                                        <span class="error ml-10">※ 20mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span>
                                        <input v-show="false" ref="attachFile2" type="file" @change="uploadFile($event, $refs.attachFile2, 2)" />
                                    </div>

                                    <div class="mb-20">
                                        <span class="ml-10" v-if="attachFileUrl3" style="cursor:pointer">
                                            <a @click="newOpen(attachFileUrl3)" class="w-300px pointer txt-black">{{attachFileName3}}</a>
                                        </span>
                                        <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile3.click()">파일 선택</button>
                                        <span v-if="attachFile3" class="ml-10 bold" style="cursor:pointer" >{{attachFile3.name}}</span>
                                        <span class="error ml-10">※ 20mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span>
                                        <input v-show="false" ref="attachFile3" type="file" @change="uploadFile($event, $refs.attachFile3, 3)" />
                                    </div>

                                    <div>
                                        <span class="ml-10" v-if="attachFileUrl4" style="cursor:pointer">
                                            <a @click="newOpen(attachFileUrl4)" class="w-300px pointer txt-black">{{attachFileName4}}</a>
                                        </span>
                                        <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile4.click()">파일 선택</button>
                                        <span v-if="attachFile4" class="ml-10 bold" style="cursor:pointer" >{{attachFile4.name}}</span>
                                        <span class="error ml-10">※ 20mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span><br>
                                        <input v-show="false" ref="attachFile4" type="file" @change="uploadFile($event, $refs.attachFile4, 4)" /><br>
                                    </div>

                                    <div>
                                        <span class="ml-10" v-if="attachFileUrl5" style="cursor:pointer">
                                            <a @click="newOpen(attachFileUrl5)" class="w-300px pointer txt-black">{{attachFileName5}}</a>
                                        </span>
                                        <button type="button" class="default-btn ml-10" name="fileUpload" @click="$refs.attachFile5.click()">파일 선택</button>
                                        <span v-if="attachFile5" class="ml-10 bold" style="cursor:pointer" >{{attachFile5.name}}</span>
                                        <span class="error ml-10">※ 20mb이하의 pdf, jpg, jpeg, png, gif 파일만 업로드 가능합니다.</span><br>
                                        <input v-show="false" ref="attachFile5" type="file" @change="uploadFile($event, $refs.attachFile5, 5)" /><br>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle pointer" @click="putPersonInfo()">수정</a>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
import {
    GetUrlParams
} from '@/utils/common.js'
export default {
    data: () => ({
        idxCrmCenter: -1,
        name: '',
        gender: '',
        birth: '',
        email: '',
        idxCrmRoute: -1,
        metaList: [],
        routeList: [],
        idxCrmMetaRoute: -1,
        idx: -1,
        phone1: '010', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        isPhoneVaild:true,
        numberRegExp:/^[0-9]{3,}$/, /* 숫자 정규식 */
        originPhone: '', /* 최초 Load시 휴대폰 번호 */
        ageRange:'', /* 연령선택 */
        attachFile: '', // 첨부파일
        attachFile2: '', // 첨부파일
        attachFile3: '', // 첨부파일
        attachFile4: '', // 첨부파일
        attachFile5: '', // 첨부파일
        attachFileName: '',
        attachFileName2: '',
        attachFileName3: '',
        attachFileName4: '',
        attachFileName5: '',
        attachFileUrl: '',
        attachFileUrl2: '',
        attachFileUrl3: '',
        attachFileUrl4: '',
        attachFileUrl5: '' 

    }),


    mounted() {
        this.getMetaList()
        var oParams = GetUrlParams()
        this.idx = oParams.idx
        this.getPersonData(this.idx);
    },

    watch:{
        phone1(){
            this.isPhoneVaild = false;
        },
        phone2(val){
            if(val !== this.originPhone[1]){
                this.isPhoneVaild = false;
            }
        },
        phone3(val){
            if(val !== this.originPhone[2]){
                this.isPhoneVaild = false;
            }
        },
    },

    methods: {

        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;
            return [year, month, day].join('-');
        },

        getPersonData(idx) {
            this.axios.get(`/api/v1/client/person/${idx}`, {
                })
                .then(res => {
                    console.log(res.data);
                    if (res.data.err === 0) {
                        for (var item of Object.keys(res.data.personVO)) {
                            if (typeof this[item] !== 'undefined') {

                                this[item] = res.data.personVO[item]
                            } 

                            // 휴대폰 번호 매핑
                            if (item === 'phone') {
                                var phone = res.data.personVO[item].split('-')
                                if (phone.length === 3) {
                                    this.phone1 = phone[0]
                                    this.phone2 = phone[1]
                                    this.phone3 = phone[2]
                                    this.originPhone = phone;
                                }
                                continue
                            }
                        }
                    }
                })
                .catch(err => {
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        putPersonInfo() {
            if (!EmptyCheck(this.name, '고객명을')) {
                return false
            }

            if(!this.gender){
                alert('성별을 선택해주세요');
                return false;
            }

           // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }
            
            if(!this.isPhoneVaild){
                alert('휴대폰 번호 중복 확인을 해주세요.');
                return false;
            }

            var vo = new FormData();

            vo.append('name',this.name);
            vo.append('gender',this.gender);
            vo.append('birth',this.formatDate(this.birth));
            vo.append('email',this.email);
            vo.append('idxCrmMetaRoute',this.idxCrmMetaRoute);
            vo.append('phone',phone);
            vo.append('ageRange',this.ageRange);

            // 각 파일 빈 binary로 추가해놓기
            vo.append('attachFile', new Blob())
            vo.append('attachFile2', new Blob())
            vo.append('attachFile3', new Blob())
            vo.append('attachFile4', new Blob())
            vo.append('attachFile5', new Blob())

            if (this.attachFile) {
                vo.delete('attachFile')
                vo.append('attachFile', this.attachFile)
            }

            if (this.attachFile2) {
                vo.delete('attachFile2')
                vo.append('attachFile2', this.attachFile2)
            }

            if (this.attachFile3) {
                vo.delete('attachFile3')
                vo.append('attachFile3', this.attachFile3)
            }

            if (this.attachFile4) {
                vo.delete('attachFile4')
                vo.append('attachFile4', this.attachFile4)
            }

            if (this.attachFile5) {
                vo.delete('attachFile5')
                vo.append('attachFile5', this.attachFile5)
            }

            this.axios.post(`/api/v1/client/person/${this.idx}`, vo, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
                if (res.data.err === 0) {
                    alert('고객정보 수정이 완료되었습니다.')
                    history.back()
                    // this.$router.push('/origin/person_manage')
                } else {
                    alert(res.data.result)
                }
            })
            .catch(err => {
                if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                } else {
                    alert('관리자에게 문의하세요')
                }
            })
        },

        // 유입경로, 상담구분, 상담유형 리스트
        getMetaList() {
            this.axios.get('/api/v1/client/meta', {})
                .then(res => {
                    if (res.data.err === 0) {
                        this.routeList = res.data.routeList
                    }
                })
                .catch(err => {
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })
        },

        // 상담예약관리 페이지로 돌아가기
        cancel() {
            //this.$router.push('/origin/customer_manage')
            history.back()
        },

        checkPhoneVaild(){
            /**
             * 1.중복일경우 alert => 사용중인 휴대폰 번호 입니다. 다른번호를 입력해주세요
             * 2.숫자가 아닌 값이 있을 시 alert => 사용할 수 없는 휴대폰 번호 입니다.(O)
             * 3.중복이 없을 경우 alert => 사용가능한 번호입니다.
             * 4.입력 x alert => 휴대폰 번호를 입력하세요(O)
             */

            
             if(!this.phone1 || !this.phone2 || !this.phone3){
                 alert('휴대폰 번호를 입력하세요');
                 return false;
             }

             if(!this.numberRegExp.test(this.phone1) || !this.numberRegExp.test(this.phone2) || !this.numberRegExp.test(this.phone3)){
                 alert('사용할 수 없는 휴대폰 번호 입니다. 숫자만 입력해주세요.');
                 /**
                  * 숫자가 아닌 경우 초기화
                  */
                 if(!this.numberRegExp.test(this.phone1)){
                     this.phone1 ='';
                 }
                 if(!this.numberRegExp.test(this.phone2)){
                     this.phone2 ='';
                 }
                 if(!this.numberRegExp.test(this.phone3)){
                     this.phone3 ='';
                 }
                 return false;
             }

             this.axios.get(`/api/v1/client/person/dup_phone`,{
                 params:{
                    'phone' : `${this.phone1}-${this.phone2}-${this.phone3}`
                 }
             })
             .then((response)=>{
                const {err,result} = response.data;

                if(err === 100){
                    alert(result);
                }else{
                    alert(result);
                    this.isPhoneVaild = true;
                }
             }).catch(err=>{
                 if (err.response.data.path == '/login/msg') {
                    alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                    this.$router.push('/login')
                 } 
             });
        },

        checkDiffPhone(){
            if((this.phone1 !== this.originPhone[0]) || (this.phone2 !== this.originPhone[1]) || (this.phone3 !== this.originPhone[2])){
                this.isPhoneVaild = false;
            }else{
                this.isPhoneVaild = true;
            }
        },
        //파일업로드
        uploadFile(event, attachFile, i) {
            // // 포맷 검사
             var allowFormat = ['pdf', 'jpg', 'png', 'gif', 'PDF', 'JPG', 'PNG', 'GIF']
             var checked = false
             for (var item of allowFormat) {
                 if (event.target.files[0].name.includes(item)) {
                     checked = true
                     break
                 } else {
                     continue
                 }
            }

            if(checked) {
                // 용량 검사
                if (event.target.files[0].size > 1048576 * 20) {
                    alert('20MB 이하만 업로드 가능합니다.')
                    attachFile.value = ''
                    return false
                } else {

                    switch (i) {
                        case 1:
                        this.attachFile = event.target.files[0]       
                            break;
                        case 2:
                        this.attachFile2 = event.target.files[0]
                            break;
                        case 3:
                        this.attachFile3 = event.target.files[0]
                            break;
                        case 4:
                        this.attachFile4 = event.target.files[0]
                            break;
                        case 5:
                        this.attachFile5 = event.target.files[0]
                            break;
                    }
                    attachFile.value = ''
                }
            } else {
                alert('파일 포맷은 pdf, jpg, jpeg, png, gif형식만 가능합니다.')
                this.$refs.attachFile.value = ''
                return false
            }
        },
        /**
         * 새페이지 오픈
         */
         newOpen(url){
            if(url){
                window.open(url,'_blank');
            }
            else{
                alert('실제 파일이 존재하지 않습니다.');
            }
        },
    }
}
</script>

